<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn color="grey" @click="$router.push({name: 'admin.price-plans.create'})">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="pricePlans"
            :items-per-page="15"
            class="elevation-1"
        >

            <template v-slot:item.name="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClickName(item)">
                        {{ item.name }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import PricePlan from "../../../models/PricePlan";

export default {
    name: "users-index",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            pricePlans: [],
            loading: false,
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
            ],
        }
    },
    methods: {
        handleClickName(plan) {
            this.$router.push({name: 'admin.price-plans.edit', params: {id: plan.id}})
        }
    },
    async mounted() {
        this.loading = true
        this.pricePlans = await PricePlan.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
